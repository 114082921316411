

















































































































import Vue from "vue";
import { CandidateDashboard } from "@/interfaces/candidate/candidate_dashboard/candidate_dashboard";
import CareerPath from "@/components/candidate/candidate_dashboard/CareerPath.vue";
import MatchingJobs from "@/components/candidate/candidate_dashboard/MatchingJobs.vue";
import HelloUser from "@/components/shared/HelloUser.vue";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS, USER_PROFILE } from "@/store/modules/auth/constants";
import router from "@/router";
import {
  GET_JOBS_LOADING,
  GET_TOP_MATCHING_JOB
} from "@/store/modules/candidates/constants";
import { format_overall_score } from "@/utils/global";
import { get_color_by_matching_score } from "@/utils/colors";
import AssessmentTests from "@/components/candidate/candidate_dashboard/AssessmentTests.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { scoreUtils } from "@/utils";

export default Vue.extend({
  name: "CandidateDashboard",
  components: {
    AssessmentTests,
    HelloUser,
    MatchingJobs,
    CareerPath
  },
  data(): CandidateDashboard {
    return {
      navigate_icon: require("@/assets/icons/arrow-right.svg"),
      font_color: "",
      background_color: "",
      color_code: "",
      skill_gap: "",
      profile_score: 0
    };
  },
  computed: {
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS,
      user_profile_details: USER_PROFILE
    }),
    ...mapGetters("candidate", {
      get_top_matching_job: GET_TOP_MATCHING_JOB,
      matching_job_loading: GET_JOBS_LOADING
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  watch: {
    get_top_matching_job(n) {
      if (n) {
        this.skill_gap = (1 - n.score).toString();
        this.set_color();
      }
    }
  },
  mounted() {
    this.profile_score = scoreUtils.calculateProfileScore(
      this.user_profile_details,
      this.user_details
    );
    if (this.get_top_matching_job) {
      const color = get_color_by_matching_score(
        this.get_top_matching_job.score
      ); // Get random color
      this.font_color = `color: ${color}`;
      this.background_color = `background: ${color}`;
      this.color_code = color;
      this.skill_gap = (1 - this.get_top_matching_job.score).toString();
    }
  },
  methods: {
    format_overall_score,
    async view_profile() {
      await router.push(`/candidate/profile`);
    },
    set_color() {
      const color = get_color_by_matching_score(
        this.get_top_matching_job.score
      ); // Get random color
      this.font_color = `color: ${color}`;
      this.background_color = `background: ${color}`;
      this.color_code = color;
    }
  }
});
