var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"candidate_dashboard"}},[_c('div',{staticClass:"hello"},[_c('HelloUser'),_c('div',{staticClass:"skill-profile-details"},[_c('div',{staticClass:"d-flex align-start align-md-center flex-column flex-md-row"},[_c('div',{staticClass:"base-card skill-gap-card d-flex align-center"},[(_vm.matching_job_loading)?[_c('v-skeleton-loader',{attrs:{"type":"avatar"}})]:(_vm.get_top_matching_job)?_c('v-progress-circular',{attrs:{"value":_vm.format_overall_score(_vm.get_top_matching_job.score),"size":"70","width":"7","color":_vm.color_code,"rotate":"120"},on:{"click":function($event){return _vm.$router.push("/candidate/jobs")}}},[_c('p',{staticClass:"mb-0 base-body-text value",style:(_vm.font_color)},[_vm._v(" "+_vm._s(_vm.format_overall_score(_vm.skill_gap))+"% ")])]):_vm._e(),_c('p',{class:[
              'mb-0',
              'base-body-text',
              'title',
              _vm.get_site_direction === _vm.SiteDirections.RTL ? 'mr-2' : 'ms-3'
            ]},[_vm._v(" "+_vm._s(_vm.$t("candidate.dashboard.skill-gap"))+" ")]),_c('v-btn',{staticClass:"navigate-icon ms-auto",attrs:{"to":_vm.get_top_matching_job
                ? 'jobs/view/' + _vm.get_top_matching_job.jid
                : '',"icon":"","loading":_vm.matching_job_loading,"disabled":!_vm.get_top_matching_job,"x-large":"","color":"primary"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" "+_vm._s(_vm.get_site_direction === _vm.SiteDirections.LTR ? "mdi-arrow-right-thin" : "mdi-arrow-left-thin")+" ")])],1)],2),_c('div',{staticClass:"base-card profile-status-card d-flex align-center"},[_c('v-progress-circular',{attrs:{"value":_vm.profile_score,"size":"70","width":"7","color":"success","rotate":"120"}},[_c('p',{staticClass:"mb-0 base-body-text value"},[_vm._v(_vm._s(_vm.profile_score)+"%")])]),_c('p',{class:_vm.get_site_direction === _vm.SiteDirections.LTR
                ? 'mb-0 ms-3 base-body-text title'
                : 'mb-0 ms-3 mr-3 base-body-text title'},[_vm._v(" "+_vm._s(_vm.$t("candidate.dashboard.profile-status"))+" ")]),_c('v-btn',{staticClass:"navigate-icon ms-auto",attrs:{"icon":"","x-large":"","color":"primary"},on:{"click":_vm.view_profile}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" "+_vm._s(_vm.get_site_direction === _vm.SiteDirections.LTR ? "mdi-arrow-right-thin" : "mdi-arrow-left-thin")+" ")])],1)],1)])])],1),_c('CareerPath'),_c('v-row',{staticClass:"details-section"},[_c('v-col',{staticStyle:{"max-height":"800px","height":"800px"},attrs:{"cols":"12","lg":"6"}},[_c('AssessmentTests')],1),_c('v-col',{staticStyle:{"max-height":"800px","height":"800px"},attrs:{"cols":"12","lg":"6"}},[_c('MatchingJobs')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }