var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"base-card assessments-tests"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('p',{staticClass:"base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("candidate.dashboard.test-card-title"))+" ")])]),_c('div',{staticClass:"tests"},_vm._l((_vm.assessments),function(assessment,index){return _c('div',{key:index},[_c('div',{staticClass:"\n          d-flex\n          flex-column flex-md-row\n          align-start align-lg-center\n          base-card\n          test-card\n          my-2\n        "},[_c('div',{staticClass:"\n            d-flex\n            align-center\n            flex-md-column flex-xl-row\n            justify-space-between\n          ",style:({
            width: _vm.$vuetify.breakpoint.mdAndUp ? 'auto' : '100%',
            gap: _vm.$vuetify.breakpoint.mdAndUp ? '1rem' : '0'
          })},[_c('v-avatar',{attrs:{"size":_vm.$vuetify.breakpoint.lg ? 80 : 120}},[_c('v-img',{attrs:{"src":assessment.logo}})],1),(_vm.$vuetify.breakpoint.lgAndDown)?_c('v-btn',{attrs:{"outlined":"","icon":"","color":"primary","href":assessment.link,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.get_site_direction === _vm.SiteDirections.LTR ? "mdi-chevron-right" : "mdi-chevron-left"))])],1):_vm._e()],1),_c('div',{},[_c('p',{staticClass:"mb-1 base-section-title test-details-title"},[_vm._v(" "+_vm._s(assessment.title)+" ")]),_c('p',{staticClass:"mb-4 base-body-text test-details-description"},[_vm._v(" "+_vm._s(assessment.description)+" ")]),_c('div',{staticClass:"d-flex align-start flex-row flex-wrap",staticStyle:{"gap":"1rem"}},[_c('v-chip',{attrs:{"color":"blue-light","text-color":"primary-light"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-file-document-outline")]),_c('span',{class:{
                  'mr-1': _vm.get_site_direction === _vm.SiteDirections.RTL
                }},[_vm._v(" "+_vm._s(assessment.type)+" ")])],1),_c('v-chip',{attrs:{"color":"warning-light","text-color":"warning"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-clock-time-eight-outline")]),_c('span',{class:{
                  'mr-1': _vm.get_site_direction === _vm.SiteDirections.RTL
                }},[_vm._v(" "+_vm._s(assessment.time)+" ")])],1),_c('v-chip',{attrs:{"color":"pink-light","text-color":"pink"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-chart-line-variant")]),_c('span',{class:{
                  'mr-1': _vm.get_site_direction === _vm.SiteDirections.RTL
                }},[_vm._v(" "+_vm._s(assessment.level)+" ")])],1)],1)]),(!_vm.$vuetify.breakpoint.lgAndDown)?_c('v-btn',{attrs:{"outlined":"","icon":"","color":"primary","href":assessment.link,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.get_site_direction === _vm.SiteDirections.LTR ? "mdi-chevron-right" : "mdi-chevron-left"))])],1):_vm._e()],1)])}),0),_c('div',{staticClass:"mt-8"},[_c('div',{staticClass:"\n        mb-5\n        d-flex\n        flex-column flex-md-row flex-lg-column flex-xl-row\n        align-start align-md-center align-lg-start align-xl-center\n        justify-space-between\n      "},[_c('p',{staticClass:"mb-0 base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("assessments.info-title"))+" ")]),(_vm.get_assessment_questions.assessmentProgress.assessmentComplete)?_c('v-btn',{staticClass:"base-body-text text-capitalize mx-n5 mx-md-n0",attrs:{"disabled":_vm.tenant_info.error,"to":_vm.AppRoutes.Candidae.PERSONALITY_ASSESSMENT_REPORT +
          _vm.tenant_info.details.id,"loading":_vm.get_assessment_loading,"text":"","color":"primary"}},[_c('v-icon',{class:{
            'rotate-icon': _vm.get_site_direction === _vm.SiteDirections.RTL
          },attrs:{"color":"primary"}},[_vm._v("mdi-play")]),_vm._v(" "+_vm._s(_vm.$t("assessments.report"))+" ")],1):_c('v-btn',{staticClass:"base-body-text mx-n5 mx-md-n0",attrs:{"disabled":_vm.tenant_info.error,"to":_vm.AppRoutes.Candidae.ASSESSMENTS,"loading":_vm.get_assessment_loading,"text":"","color":"primary"}},[_c('v-icon',{class:{
            'rotate-icon': _vm.get_site_direction === _vm.SiteDirections.RTL
          },attrs:{"color":"primary"}},[_vm._v("mdi-play")]),(
            _vm.get_assessment_questions.assessmentProgress.fractionComplete === 0
          )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("assessments.take"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("assessments.resume"))+" ")])],1)],1),_c('div',{staticClass:"assessments-tests-principle pa-3 px-md-5 py-md-8"},[_c('div',{staticClass:"d-flex flex-column flex-md-row",staticStyle:{"gap":"1rem"}},[_c('v-avatar',{staticClass:"principle-avatar",attrs:{"size":50}},[_c('v-img',{attrs:{"src":require("@/assets/logos/principles-small-logo.svg")}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"\n              mb-3\n              d-flex\n              flex-row flex-lg-column flex-xl-row\n              align-start align-md-center align-lg-start align-xl-center\n              justify-space-between\n            "},[_c('div',[_c('p',{staticClass:"mb-1 mr-2"},[_vm._v(" "+_vm._s(_vm.$t("assessments.info-title"))+" "+_vm._s(_vm.$t("shared.test"))+" ")]),_c('ByPrincipleUsLogo')],1),(_vm.$vuetify.breakpoint.xl)?_c('div',[(
                  _vm.get_assessment_questions.assessmentProgress
                    .assessmentComplete
                )?[_c('v-btn',{staticClass:"base-body-text text-capitalize",attrs:{"disabled":_vm.tenant_info.error,"to":_vm.AppRoutes.Candidae.PERSONALITY_ASSESSMENT_REPORT +
                    _vm.tenant_info.details.id,"loading":_vm.get_assessment_loading,"text":"","color":"primary"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("assessments.report"))+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.get_site_direction === _vm.SiteDirections.LTR ? "mdi-arrow-right-thin" : "mdi-arrow-left-thin")+" ")])],1)])]:[_c('v-btn',{staticClass:"base-body-text",attrs:{"disabled":_vm.tenant_info.error,"to":_vm.AppRoutes.Candidae.ASSESSMENTS,"loading":_vm.get_assessment_loading,"text":"","color":"primary"}},[(
                      _vm.get_assessment_questions.assessmentProgress
                        .fractionComplete === 0
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("assessments.take"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("assessments.resume"))+" ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.get_site_direction === _vm.SiteDirections.LTR ? "mdi-arrow-right-thin" : "mdi-arrow-left-thin")+" ")])],1)]],2):_vm._e()]),_c('div',{staticClass:"d-flex align-start flex-row categories-chips",staticStyle:{"gap":"1rem"}},[_c('v-chip',{attrs:{"color":"blue-light","text-color":"primary-light"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-file-document-outline")]),_c('span',{class:{ 'mr-1': _vm.get_site_direction === _vm.SiteDirections.RTL }},[_vm._v(" "+_vm._s(_vm.$t("shared.profile.personality"))+" ")])],1),_c('v-chip',{attrs:{"color":"pink-light","text-color":"pink"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-chart-line-variant")]),_c('span',{class:{ 'mr-1': _vm.get_site_direction === _vm.SiteDirections.RTL }},[_vm._v(" "+_vm._s(_vm.$t("shared.entry"))+" ")])],1)],1),(!_vm.$vuetify.breakpoint.xl)?_c('div',{staticClass:"d-flex justify-lg-start mt-5",class:_vm.$vuetify.breakpoint.xs ? 'justify-start' : 'justify-end'},[(
                _vm.get_assessment_questions.assessmentProgress.assessmentComplete
              )?[_c('v-btn',{staticClass:"base-body-text text-capitalize",attrs:{"disabled":_vm.tenant_info.error,"to":'assessments/report/' + _vm.tenant_info.details.id,"loading":_vm.get_assessment_loading,"text":"","color":"primary"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("assessments.report"))+" "),_c('v-icon',{staticClass:"assessment-report-icon",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.get_site_direction === _vm.SiteDirections.LTR ? "mdi-arrow-left-thin" : "mdi-arrow-right-thin")+" ")])],1)])]:[_c('v-btn',{staticClass:"base-body-text",attrs:{"disabled":_vm.tenant_info.error,"to":"assessments","loading":_vm.get_assessment_loading,"text":"","color":"primary"}},[(
                    _vm.get_assessment_questions.assessmentProgress
                      .fractionComplete === 0
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("assessments.take"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("assessments.resume"))+" ")]),_c('v-icon',{staticClass:"assessment-report-icon",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.get_site_direction === _vm.SiteDirections.LTR ? "mdi-arrow-right-thin" : "mdi-arrow-left-thin")+" ")])],1)]],2):_vm._e()])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }