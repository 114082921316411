import { render, staticRenderFns } from "./JobList.vue?vue&type=template&id=da8c2d8a&scoped=true&"
import script from "./JobList.vue?vue&type=script&lang=ts&"
export * from "./JobList.vue?vue&type=script&lang=ts&"
import style0 from "./JobList.vue?vue&type=style&index=0&id=da8c2d8a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da8c2d8a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemTitle,VProgressCircular})
